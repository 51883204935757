<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
        <el-form :inline="true">
            <el-form-item label="标识：">
                <el-input v-model="search_config_key" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="描述：">
                <el-input v-model="search_config_name" placeholder=""></el-input>
            </el-form-item>
            <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button>
                </el-form-item>
        </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">添加</el-button>
    </div>
    <!-- 表单 -->
    <el-table 
        v-loading="loading"
        :data="configList"
        border
        style="width: 100%;">
        <el-table-column fixed label="编号" prop="id" width="50"></el-table-column>
        <el-table-column label="标识" prop="config_key"></el-table-column>
        <el-table-column label="值" prop="config_value"></el-table-column>
        <el-table-column label="描述" prop="config_name"></el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
            <el-button type="primary" size="mini" @click="getConfigData(item.row.id)">修改</el-button>
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="标识">
            <el-input v-model="config_key" :disabled="id!=''" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="值">
            <el-input v-model="config_value" type="textarea" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="描述">
            <el-input v-model="config_name" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem('common'))
    import * as api from "@/config/api";
    export default {
        name: 'configItem',
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                dialog: false,
                configList: [],


                id: '',
                config_key: '',
                config_value: '',
                config_name: '',

                search_config_key: '',
                search_config_name: ''
            }
        },
        filters: {},
        mounted() {
            this.getConfigList()
        },
        methods: {
            // 获取列表
            getConfigList(page, size) {
                api.getConfigList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                    config_key: this.search_config_key,
                    config_name: this.search_config_name,
                }, res => {
                    this.configList = res.data.list
                    this.total = Number(res.data.count)
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getConfigList()
            },
            currentChange(val) {
                this.page = val
                this.getConfigList()
            },
            //搜索
            seach(page) {
                this.page = page
                this.getConfigList(page)
            },
            reset(page) {
                this.search_id = ''
                this.page = page
                this.getConfigList(page)
            },
            // 复制
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 上传图片
            imgUrl(val) {
                this.img_url = val
            },
            // 获取详情
            getConfigData(id) {
                this.id = id
                api.getConfigData({
                    id: id
                }, res => {
                    this.config_key = res.data.config_key
                    this.config_value = res.data.config_value
                    this.config_name = res.data.config_name
                    this.dialog = true
                })
            },
            // 添加选品
            add() {
                this.id = ''
                this.config_key = ''
                this.config_value = ''
                this.config_name = ''
                this.dialog = true
            },
            // 修改、添加频道分类
            alter() {
                if (this.id != '') {
                    api.updateConfig({
                        id: this.id,
                        config_key: this.config_key,
                        config_value: this.config_value,
                        config_name: this.config_name,
                    }, res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialog = false
                        this.getConfigList()
                    })
                    return
                }
                api.addConfig({
                    config_key: this.config_key,
                    config_value: this.config_value,
                    config_name: this.config_name,
                }, res => {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    this.dialog = false
                    this.getConfigList()
                })
            },
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>